import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import AnonymousLayout from "../anonymousLayout";
import ProtectedRoute from "../components/ProtectedRoutes";
import { GlobalContext } from "../global_context";
import AppLayout from "../layouts";
import Login from "../pages/Login";
import Projects from "../pages/Projects";
import Questionnaire from "../pages/Questionnaire";
import AnonymousSocialQuestionnaire from "../pages/Anonymous&SocialQuestionnaire";
import WebClientSetting from "../components/WebClientSetting/WebClientSetting";

const AppRoutes = () => {
  const { auth } = useContext(GlobalContext);
  return (
    <Routes>
      <Route path="/" element={<Login />} />

      <Route path="projects" element={<AppLayout />}>
        <Route
          path="/projects"
          element={
            <ProtectedRoute user={auth}>
              {" "}
              <Projects />{" "}
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/projects/:id"
          element={
            <ProtectedRoute user={auth}>
              {" "}
              {/* <Questionnaire />{" "} */}
              <WebClientSetting/>{" "}
            </ProtectedRoute>
          }
        />
      </Route>
      <Route element={<AnonymousLayout />}>
        <Route path="/:id" element={<AnonymousSocialQuestionnaire />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
