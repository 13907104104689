import React, { useEffect, useState } from "react";
import "./style.css";
import Chart from "../../Chart/Chart";
function ChartSummary({ chartData }) {
  const [options, setOptions] = useState({
    backgroundColor: "#0D349B",

    height: 102,
    width: 200,
    animationEnabled: true,

    // title: {
    //   text: "Basic Column Chart in React"
    // },
    axisY: {
      lineThickness: 0,
      gridThickness: 0,
      tickLength: 0,
      minimum: 0, // Set the minimum value for the Y-axis
      maximum: 150, // Set the maximum value for the Y-axis
      // interval: 10 ,
      labelFormatter: function (e) {
        return "";
      },
      // valueFormatString:"0'%'"
    },
    axisX: {
      lineThickness: 0,
      gridThickness: 0,
      tickLength: 0,

      titleFontColor: "white", // Set the color of the X-axis title text
      labelFontColor: "white", // Set the color of X-axis labels
      labelFontSize: 10,
      labelAngle: 0,
    },

    data: [
      {
        type: "column",
        indexLabel: "{y}%",
        indexLabelFontColor: "white",
        color: "white",
        // percentFormatString: "#0.##",
        // indexLabelFontSize: 3,
        toolTipContent: "{y} (%)",

        dataPoints: [
          { label: " ", y: 0 },
          { label: " ", y: 0 },
          { label: " ", y: 0 },
        ],
      },
    ],
  });

  const [mainData, setMainData] = useState({});

  console.log("chartdata", chartData);
  console.log("mainData", mainData);


  useEffect(() => {
    if (chartData) {
      debugger;
      // console.log("options", options);

      // let tmepobj = {
      //   overall: [0],
      //   others: [0,1],
      // };
     
      let tmepobj = {
        overall: null,
        others: [],
      };

      chartData.forEach((cur) => {
        if (cur.label === "Over All") {
          tmepobj.overall = cur;
        } else {
          tmepobj.others.push(cur);
        }
      });

      setMainData(tmepobj);

      //for overall
      // console.log(
      //   "tmepobj",
      //   tmepobj,
      //   tmepobj.overall.waves[tmepobj.overall.waves.length - 1].label
      // );

      //for others
      // console.log("others", tmepobj.others);

      let tempDataPoints = tmepobj.others.map((cur) => {
        return { label: cur.label, y: cur.waves[cur.waves.length - 1].value };
      });

      // console.log("tempDataPoints", tempDataPoints);

      let tmepOptions = { ...options };
      tmepOptions.data[0].dataPoints = tempDataPoints;
      // console.log("tmepOptions summary", tmepOptions);
      setOptions(tmepOptions);
    }
  }, [chartData]);

  // useEffect(() => {
  //   console.log("summary", options);
  // }, [options]);

  //2nd last and 3rd last
  // function elementsToShow(arr) {
  //   if(Array.isArray(arr)){
  //   if (arr.length > 2) {
  //     return arr.slice(arr.length - 3, arr.length - 1);
  //   } else if (arr.length <= 2 && arr.length >= 1) {
  //     return arr.slice(0, arr.length - 1);
  //   } else {
  //     return [];
  //   }
  // }
  // }
  //last and 2nd last
  function elementsToShow(arr) {
    if(Array.isArray(arr)){
    if (arr.length > 2) {
      return arr.slice(arr.length - 2, arr.length);
    } else if (arr.length <= 2 && arr.length >= 1) {
      return arr.slice(0, arr.length);
    } else {
      return [];
    }
  }
  }


  return (
    <div id="ChartSummary" className="mt-4">
      <div className="row align-items-center">
        <div className="col-xl-4">
          <div className="blue-container">
            <div className="d-flex align-items-center">
              <div className="result me-2">
                <div className="d-flex align-items-center">
                  <span className="span1">
                    {mainData?.overall?.label}{" "}
                    {/* {mainData?.overall?.waves[
                      mainData?.overall?.waves?.length - 1
                    ]?.label} */}
                  </span>
                  <span className="span2">
                    {mainData?.overall?.waves[
                      mainData?.overall?.waves?.length - 1
                    ]?.value || 0}
                    %
                  </span>
                </div>
              </div>

              <div className="chartGraph text-center">
                <Chart options={options} />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <table class="table table-borderless">
            <thead>
              <tr className="border_bottom">
                <th scope="col" className="b-bottom"></th>

                {/* {mainData?.others?.[0]?.waves?.map((wave, index) => ( */}
                {elementsToShow(mainData?.others?.[0]?.waves)?.map((wave, index) => (
                 
                   <th scope="col" className="b-bottom">
                   {wave.waveLabel}
                 </th>
                ))}

{!mainData?.others?.length  &&
<>
                <th scope="col" className="b-bottom">
                  wave
                </th>
                <th scope="col" className="b-bottom">
                  wave
                </th>
   </>
}
              </tr>
            </thead>
            <tbody>

              {mainData?.others?.map((cur, index) => (
                <tr>
                  <td style={{ fontWeight: "500" }}>{cur.label}</td>
                  {elementsToShow(cur?.waves)?.map((waves, i) => (
                    <td>{waves.value}%</td>
                  ))}
                </tr>
              ))}

{!mainData?.others?.length  &&
               <tr>
                <td style={{ fontWeight: "500" }}>
                  
                </td>
                <td>0%</td>
                <td>0%</td>
              </tr>

}

              {/* <tr>
                <td style={{ fontWeight: "500" }}>
                  Interior, Exterior & Ambiance
                </td>
                <td>86%</td>
                <td>71%</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td style={{ fontWeight: "500" }}>
                  Interior, Exterior & Ambiance
                </td>
                <td>86%</td>
                <td>71%</td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td style={{ fontWeight: "500" }}>Reception</td>
                <td>24%</td>
                <td>71%</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: "500" }}>Skills Evaluation</td>
                <td>86%</td>
                <td>90%</td>
                <td></td>
                <td></td>
              </tr> */}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ChartSummary;
