import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { clientComments, getQvForWave } from "../../apis";
import { toast } from "react-toastify";
import ManageVideo from "../ManageVideo/ManageVideo";

function QuestionInputTab({
  levelsDropdown,
  auth,
  selectedWave3,
  setSelectedWave3,
  selectedGeneralDetailFilterValues3,
  setSelectedGeneralDetailFilterValues3,
  WavesList,
  handleLoading,
  loading,
  projectId,
}) {
  // const [selectedWave3, setSelectedWave3] = useState("");
  // const [
  //   selectedGeneralDetailFilterValues3,
  //   setSelectedGeneralDetailFilterValues3,
  // ] = useState({});

  const [qV, setQV] = useState([]);

  const [lastSelectedName, setLastSelectedName] = useState("");
  const [mediaUrl, setMediaUrl] = useState([]);

  console.log("mediaUrl", mediaUrl, mediaUrl?.detail?.[0]?.value, "qV", qV);

  const changeClientCom = (value, mainObj, subObj, firstIndex) => {
    debugger;
    console.log("value", value, mainObj, subObj);
    let temp = { ...qV };
    console.log(
      "temp?.detail[firstIndex]?.value?.mainObj?.subObj",
      temp?.detail?.[firstIndex]?.value?.[mainObj]?.[subObj],
      "temp?.detail[firstIndex]",
      temp?.detail[firstIndex],
      "temp[detail][firstIndex][value][mainObj][subObj][clientComment]",
      temp["detail"][firstIndex]["value"][mainObj][subObj]["clientComment"]
    );

    //  temp?.mainObj?.subObj?.clientComment = value;
    // temp[mainObj][subObj]["clientComment"] = value;
    // temp?.detail?.[firstIndex]?.value?.[mainObj]?.[subObj]["clientComment"] = value;
    temp["detail"][firstIndex]["value"][mainObj][subObj]["clientComment"] =
      value;

    setQV(temp);

    console.log("temp", temp);
  };

  const handleSubmit = async () => {
    handleLoading("getQvForWave", true);

    debugger;
    let payload = {
      projectId: projectId,
      ...selectedGeneralDetailFilterValues3,
      wave: selectedWave3,
    };

    const res = await getQvForWave(payload);
    if (res.status === 200) {
      console.log(
        "res",
        res,
        "res.data",
        res.data,
        "res?.data?.data",
        res?.data?.data
      );

      let ismediaLinks = false;
      let isQv = false;

      res?.data?.data?.forEach((cur) => {
        if (cur.title === "mediaLinks" && cur?.detail) {
          setMediaUrl(cur);
          ismediaLinks = true; 
        }
        if (cur.title === "qv" && cur?.detail) {
          console.log("check", cur);
          setQV(cur);
          isQv = true; 
        }
      });

      if(ismediaLinks === false){ setMediaUrl(null); }
      if(isQv === false){ setQV(null);}


    }

    handleLoading("getQvForWave", false);
  };

  const handleQvSave = async () => {
    handleLoading("clientComments", true);

    debugger;
    let payload = {
      projectId: projectId,
      ...selectedGeneralDetailFilterValues3,
      wave: selectedWave3,
      qv: qV,
    };

    const res = await clientComments(payload);
    if (res.status === 200) {
      console.log(
        "res",
        res,
        "res.data",
        res.data,
        "res?.data?.data",
        res?.data?.data
      );
      toast.success("Saved Successfully");

      // setQV(res?.data?.data);
    } else {
      toast.error("Something went wrong!");
    }

    handleLoading("clientComments", false);
  };

  const handelWave = (event) => {
    debugger;
    const { value, id, name } = event.target;
    console.log("id", id, name);
    // let temp = [...selectedWave3];
    const find = WavesList.find((cur) => cur.id == value);
    // console.log("find", find);
    // temp[position] = find;
    setSelectedWave3(find);
  };

  const handleGeneralDetailFilterDropdownChange = (
    event,
    dropdownName,
    lastDropdown
  ) => {
    debugger;
    const { value, id, name } = event.target;
    console.log("id", id, name);
    const find = lastDropdown?.find((cur) => cur?.id == value)?.name;
    if (find) {
      setLastSelectedName(find);
    }
    console.log("found", find);

    const res = removeLevelsAfterSelected(
      dropdownName,
      selectedGeneralDetailFilterValues3
    );
    setSelectedGeneralDetailFilterValues3((prevValues) => ({
      ...res,
      [dropdownName]: value,
    }));
  };

  // Function to remove key-value pairs after the selected level
  const removeLevelsAfterSelected = (selectedLevel, dropdownoptions) => {
    if (dropdownoptions[selectedLevel]) {
      const selectedLevelIndex =
        Object.keys(dropdownoptions).indexOf(selectedLevel);
      const keysToRemove = Object.keys(dropdownoptions).slice(
        selectedLevelIndex + 1
      );
      keysToRemove.forEach((key) => {
        delete dropdownoptions[key];
      });
    }
    return dropdownoptions;
  };
  return (
    <div>
      {/* //second tab dropdowns start */}
      <div className="d-flex flex-wrap justify-content-between mt-3">
        {loading.getFilters && "Loading..."}
        {levelsDropdown?.dropDownFilters
          ? Object.keys(levelsDropdown.dropDownFilters)?.map(
              (dropdown, index) => {
                if (index === 0) {
                  return (
                    <div key={index} className="mb-1">
                      <label htmlFor="">
                        {levelsDropdown?.levelsJSON?.[index]["name"]}
                      </label>
                      <select
                        style={{ height: "32px", width: "360px" }}
                        className="form-select form-control form-select-sm fontSize_12"
                        onChange={(e) =>
                          handleGeneralDetailFilterDropdownChange(e, dropdown)
                        }
                        value={
                          selectedGeneralDetailFilterValues3[dropdown] || ""
                        }
                        aria-label="Default select example"
                      >
                        <option selected>select option</option>
                        {levelsDropdown?.dropDownFilters[dropdown]?.map(
                          (option) => {
                            return (
                              <>
                                {console.log("Premission", option?.Permission)}
                                {(option?.Permission?.includes(
                                  auth?.userName
                                ) ||
                                  option?.Permission?.includes(
                                    auth?.id?.toString()
                                  )) && (
                                  // logic
                                  <option key={option?.id} value={option?.id}>
                                    {option?.name}
                                  </option>
                                )}
                              </>
                            );
                          }
                        )}
                      </select>
                    </div>
                  );
                } else {
                  const previousDropdownSelectedValue =
                    selectedGeneralDetailFilterValues3[
                      Object.keys(levelsDropdown.dropDownFilters)[index - 1]
                    ];
                  return (
                    <div key={index} className="mb-1">
                      <label htmlFor="">
                        {levelsDropdown?.levelsJSON?.[index]["name"]}
                      </label>
                      <select
                        value={
                          selectedGeneralDetailFilterValues3[dropdown] || ""
                        }
                        style={{ height: "32px", width: "360px" }}
                        onChange={(e) =>
                          handleGeneralDetailFilterDropdownChange(
                            e,
                            dropdown,
                            levelsDropdown?.dropDownFilters[dropdown][
                              previousDropdownSelectedValue
                            ]
                          )
                        }
                        className="form-select form-control form-select-sm fontSize_12"
                        aria-label="Default select example"
                      >
                        <option selected>select option</option>
                        {console.log(
                          "previousDropdownSelectedValue",
                          levelsDropdown?.dropDownFilters[dropdown][
                            previousDropdownSelectedValue
                          ]
                        )}
                        {/* <option key={-1} value={-1} disabled={previousDropdownSelectedValue == -1 }>All</option> */}
                        <option key={-1} value={-1}>
                          All
                        </option>
                        {/* Render options based on the selected value of the previous dropdown */}
                        {levelsDropdown?.dropDownFilters[dropdown][
                          previousDropdownSelectedValue
                        ]?.map((option) => {
                          return (
                            <option key={option?.id} value={option?.id}>
                              {option?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  );
                }
              }
            )
          : ""}
      </div>
      {/* //second tab dropdowns end */}
      {/* <div className="d-flex flex-wrap justify-content-around mt-3"> */}
      <Accordion className="mt-3" defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Questionniare</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <div className="AccordionSummary_border">
            <div className="line"></div>
          </div> */}

          <div className="d-flex align-items-baseline justify-content-between mb-3">
           
            <div className="">
              <ManageVideo mediaUrl={mediaUrl?.detail?.[0]?.value ?  mediaUrl?.detail?.[0]?.value : []} />
            </div>

            <div className="d-flex align-items-baseline">
              <div className="mb-1 me-3">
                <select
                  style={{ height: "40px", width: "250px" }}
                  className="form-select form-control form-select-sm fontSize_12"
                  onChange={(e) => handelWave(e)}
                  value={selectedWave3?.id || ""}
                  aria-label="Default select example"
                >
                  <option selected>select option</option>
                  {WavesList.map((cur, i) => (
                    <option key={cur?.name} value={cur?.id}>
                      {cur?.name}
                    </option>
                  ))}
                </select>
              </div>

              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary px-4"
                disabled={
                  selectedWave3 === "" ||
                  Object?.keys(selectedGeneralDetailFilterValues3)?.length !== 3
                }
              >
                Search
              </button>
            </div>

            {console.log(
              "zzz",
              selectedWave3,
              Object?.keys(selectedGeneralDetailFilterValues3)?.length
            )}
          </div>

          <table class="table questionare_Table">
            <thead>
              <tr>
                <th className="border" scope="col">
                  Serial&nbsp;No.
                </th>
                <th className="border" scope="col">
                  Questions
                </th>
                {/* <th scope="col">Response</th> */}
                <th className="border" scope="col">
                  Available&nbsp;Marks
                </th>
                <th className="border" scope="col">
                  Achived&nbsp;Marks
                </th>
                <th className="border" style={{ width: "20%" }} scope="col">
                  Shopper&nbsp;Comments
                </th>
                <th className="border" style={{ width: "20%" }} scope="col">
                  Client&nbsp;Comments
                </th>
              </tr>
            </thead>

            {!loading.getQvForWave && (
              <tbody>
                {console.log("qV1", qV, "qV?.detail", qV?.detail)}
                {/* {Object.entries(qV)?.map((cur, index) => ( */}
                {qV?.detail?.map((firstElement, firstIndex) => (
                  <>
                    {console.log("qV2", qV, "firstElement", firstElement)}

                    {/* {true && */}
                    {/* {firstElement?.label == selectedWave3 &&  */}
                    <>
                      {firstElement?.value &&
                        Object?.entries(firstElement?.value)?.map(
                          (cur, index) => (
                            //rendring start
                            <>
                              {console.log("qV3", qV, "firstElement", cur)}

                              <tr
                                className="border"
                                style={{
                                  backgroundColor: "#36508D",
                                  color: "white",
                                }}
                              >
                                {/* {console.log("cur[0]", cur[0], "cur[1]", cur[1], "Object.entries(qV)", Object.entries(cur[1]) )} */}

                                <th scope="row"></th>
                                <td>
                                  <b>{cur[0]}</b>
                                </td>
                                {/* <td></td>  */}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              {Object.entries(cur[1]).map((sub, i) => (
                                <tr>
                                  {/* {console.log("sub", sub, "sub[1].caption", sub[1].caption )} */}
                                  {console.log(
                                    "cur[0], sub[0]",
                                    cur[0],
                                    sub[0]
                                  )}
                                  <td className="border">{sub[1]?.serialNo}</td>
                                  <td className="border">{sub[1]?.caption}</td>
                                  {/* <td>{}</td> */}
                                  <td className="border text-center">
                                    {sub[1]?.maxValue}
                                  </td>
                                  <td className="border text-center">
                                    {sub[1]?.acheivedValue}
                                  </td>
                                  <td className="border">{sub[1]?.comment}</td>
                                  <td className="border">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={sub[1]?.clientComment || null}
                                      onChange={(e) =>
                                        changeClientCom(
                                          e.target.value,
                                          cur[0],
                                          sub[0],
                                          firstIndex
                                        )
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </>
                            //  rendring ends
                          )
                        )}
                    </>
                    {/* } */}
                  </>
                ))}
              </tbody>
            )}
          </table>

          {loading.getQvForWave && (
            <div className="table_initail_Message">Loading...</div>
          )}

          {!loading.getQvForWave && (qV?.length === 0 || qV === null) && (
            <div className="table_initail_Message">No Data</div>
          )}

          {!loading.getQvForWave && qV?.detail && (
            <div className="text-end">
              <button
                onClick={handleQvSave}
                type="button"
                className="btn btn-primary px-4"
                disabled={loading.clientComments}
              >
                {loading.clientComments ? ". . ." : "Save"}
              </button>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      {/* </div> */}
    </div>
  );
}

export default QuestionInputTab;
