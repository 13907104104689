import React from 'react'
import CanvasJSReact from '@canvasjs/react-charts';
//var CanvasJSReact = require('@canvasjs/react-charts');
 
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Chart({options}) {
  // console.log("final options", options);
  return (
    <>
       <CanvasJSChart options = {options} />
    </>
  )
}

export default Chart
