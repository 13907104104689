import React, { useEffect, useState } from "react";
import "./style.css";
import Chart from "../../Chart/Chart";

function ChartData({ heading, optionDetail, chartType, showCharGrid }) {
  const initialOptions = {
    height: 140,
    width: 1090,

    animationEnabled: true,
    // animationDuration: 2200,
    // exportEnabled: true,
    theme: "light2", // "light1", "dark1", "dark2"
    // title:{
    //     text: "Bounce Rate by Week of Year"
    // },
    // axisY: {
    //     title: "Bounce Rate",
    //     suffix: "%",
    // },
    axisY: {
      lineThickness: 0,
      gridThickness: 0,
      tickLength: 0,
      minimum: 0, // Set the minimum value for the Y-axis
      maximum: 110, // Set the maximum value for the Y-axis
    //   interval: 0,
      labelFormatter: function(e) {
          return "";
      }
    },
    axisX: {
      // title: "Week of Year",
      prefix: "",
      interval: 1,
    },

    data: [
      {
        type: "line",
        // type: "column",
        indexLabel: "{y}%", // Display y-axis values as percentages
        indexLabelFontColor: "black",
        indexLabelFontSize: 13, // Set the font size of the index labels
        // indexLabelPlacement: "inside",
        color: "#6D78AD",

        // indexLabelPlacement: "outside",
        // indexLabelPlacement: "inside", // Place the index labels inside the columns
        // indexLabelFontColor: "white", // Set the font color of the index labels to white
        // indexLabelBackgroundColor: "blue",

        toolTipContent: "{y} (%)",

        dataPoints: [
          { x: 1, y: 0 },
          { x: 2, y: 0 },
          { x: 3, y: 0 },
          { x: 4, y: 0 },
          { x: 5, y: 0 },
          { x: 6, y: 0 },
          { x: 7, y: 0 },
          { x: 8, y: 0 },
          { x: 9, y: 0 },
          { x: 10, y: 0 },
          { x: 11, y: 0 },
          { x: 12, y: 0 },
          { x: 13, y: 0 },
          { x: 14, y: 0 },
        ],
      },
    ],
  };

  const [options, setOptions] = useState({ ...initialOptions });

  useEffect(() => {
    if (chartType) {
      let tempOptions = { ...options };
      tempOptions.data[0].type = chartType;
      setOptions(tempOptions);
    }
  }, [chartType]);

  useEffect(() => {
    if (showCharGrid) {
      let tempOptions = { ...options };
      tempOptions.axisY.interval = 20;
      delete tempOptions.axisY.labelFormatter;
      delete tempOptions.axisY.lineThickness;
      delete tempOptions.axisY.gridThickness;
      delete tempOptions.axisY.tickLength;

      setOptions(tempOptions);
    } else {
      let tempOptions = { ...options };
      delete tempOptions.axisY.interval;
     tempOptions.axisY.lineThickness = 0;
     tempOptions.axisY.gridThickness = 0;
     tempOptions.axisY.tickLength = 0;
      tempOptions.axisY.labelFormatter = function (e) {
        return "";
      };
      setOptions(tempOptions);
    }
  }, [showCharGrid]);



  useEffect(() => {
    if (optionDetail) {
      updateOptionDetail();
    }
  }, [optionDetail]);

  console.log("optionDetail", optionDetail);
  const updateOptionDetail = () => {
    let dataPoints = [];
    // console.log("optionDetail", optionDetail);
    dataPoints = optionDetail.map((waves, index) => {
      return { label: waves.waveLabel, y: waves.value };
      // return {x: index, y: waves.value}
    });

    // console.log("dataPoints", dataPoints);
    if (dataPoints.length) {
      let tempOptions = { ...options };
      tempOptions.data[0].dataPoints = dataPoints;
      setOptions(tempOptions);

      // setOptions((pre) => ({
      //     ...pre,
      //     data: [{
      //       ...pre.data,
      //       dataPoints: dataPoints,
      //    }]
      // }))
    }
  };

  // useEffect(() => {
  //     // console.log("options", options);
  // }, [options])

  return (
    <div id="ChartData" className="my-4">
      <div className="white-cntainer">
        <div className="d-flex align-items-center">
          <div className="heading-box">
            <h5>{heading}</h5>
          </div>
          <div className="arrao-box px-1">
            <img
              src={require("../../../assets/Polygon 1.png")}
              width="15"
              alt=""
            />
          </div>
          <div className="graph-box px-1">
            <Chart options={options} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChartData;
