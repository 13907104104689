import React, { useEffect, useState } from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { generateCounterMeasureReport } from '../../apis';
import Artboard71 from "../../assets/Artboard71.png";
import Card from './Card';
import { CSVLink, CSVDownload } from "react-csv";

function QuestionMR({levelsDropdown, auth, selectedWave2, setSelectedWave2, counterMeasureReport, setCounterMeasureReport, selectedGeneralDetailFilterValues2, setSelectedGeneralDetailFilterValues2,   WavesList, handleLoading, loading, qV, setQV, projectId}) {

    // const [selectedWave2, setSelectedWave2] = useState([]);
    // const [counterMeasureReport, setCounterMeasureReport] = useState();
    // const [
    //   selectedGeneralDetailFilterValues2,
    //   setSelectedGeneralDetailFilterValues2,
    // ] = useState({});

  const [lastSelectedName, setLastSelectedName] = useState("");
  const [csvData, setCsvData] = useState([]);

  console.log("lastSelectedName", lastSelectedName);




   const handelWave = (event, position) => {
    debugger;
    
    const { value, id, name } = event.target;
    console.log("id", id, name);
    let temp = [...selectedWave2];
    const find = WavesList.find((cur) => cur.id == value);
    console.log("find", find);
    temp[position] = find;
    setSelectedWave2(temp);
   }

  //  useEffect(() => {
  // if(selectedWave2?.length === 2){
  //   getComparisionResut();
  // }
  //  }, [selectedWave2]);


   useEffect(() => {
    if(counterMeasureReport){
      console.log("counterMeasureReport", counterMeasureReport);
    }
     }, [counterMeasureReport]);

     useEffect(() => {
      if(csvData){
        console.log("csvData useEffect", csvData);
      }
       }, [csvData]);

     

   const getComparisionResut = async () => {
    debugger;
    handleLoading("counterMeasureReport", true);

    let payload = {
        projectId: projectId,
        ...selectedGeneralDetailFilterValues2,
        waves: selectedWave2
  //       waves: [{
  //         "id": 265,
  //         "name": "Wave-10-V2",
  //         "currentWave": true,
  //         "waveStartDate": "2023-09-14",
  //         "waveEndDate": "2023-11-30",
  //         "fieldCutOffDate": "2023-11-08",
  //         "qaCutOffDate": "2023-11-30",
  //         "studyType": "Simple Survey"
  //     },
  //     {
  //       "id": 266,
  //       "name": "Wave-10-V3",
  //       "currentWave": false,
  //       "waveStartDate": "2023-11-20",
  //       "waveEndDate": "2023-12-31",
  //       "fieldCutOffDate": "2023-11-20",
  //       "qaCutOffDate": "2023-11-30",
  //       "studyType": "Simple Survey"
  //   }
  // ]
    };

    const res = await generateCounterMeasureReport(payload);
    if (res.status === 200) {
        console.log("res", res, "res.data", res.data, "res?.data?.data", res?.data?.data);
        setCounterMeasureReport(res?.data?.data);
        }

        // const csvData = [
        //   ["firstname", "lastname", "email"],
        //   ["Ahmed", "Tomi", "ah@smthing.co.com"],
        //   ["Raed", "Labes", "rl@smthing.co.com"],
        //   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
        // ];



    
       let tempcsvData = [
          ["Serial No", "Questions", " Previous Marks", "Active Marks", "Previous Comments", " Active Comments"],
        ];

        if(res?.data?.data?.qv){
          Object?.entries(res?.data?.data?.qv)?.forEach((cur, index) => {
            tempcsvData.push([null, cur[0], null, null, null, null]);
            Object.entries(cur[1]).forEach((sub, i) => {
              tempcsvData.push([i+1, sub[1]?.caption, sub[1]?.previousAcheivedValue,  sub[1]?.currentAcheivedValue, sub[1]?.previousComment, sub[1]?.currentComment ])
            });
          });
        }
        

          console.log("tempcsvData",tempcsvData);
          setCsvData(tempcsvData);

    handleLoading("counterMeasureReport", false);

   }

   const handleGeneralDetailFilterDropdownChange = (
    event,
    dropdownName,
    lastDropdown
  ) => {
    debugger;
    const { value, id, name } = event.target;
    console.log("id", id, name);
    const find = lastDropdown?.find((cur) => cur?.id == value)?.name;
    if (find) {
      setLastSelectedName(find);
    }
    console.log("found", find);

    const res = removeLevelsAfterSelected(
      dropdownName,
      selectedGeneralDetailFilterValues2
    );
    setSelectedGeneralDetailFilterValues2((prevValues) => ({
      ...res,
      [dropdownName]: value,
    }));
  };

    // Function to remove key-value pairs after the selected level
    const removeLevelsAfterSelected = (selectedLevel, dropdownoptions) => {
      if (dropdownoptions[selectedLevel]) {
        const selectedLevelIndex =
          Object.keys(dropdownoptions).indexOf(selectedLevel);
        const keysToRemove = Object.keys(dropdownoptions).slice(
          selectedLevelIndex + 1
        );
        keysToRemove.forEach((key) => {
          delete dropdownoptions[key];
        });
      }
      return dropdownoptions;
    };

    console.log(
      "selectedGeneralDetailFilterValues2 2nd tab",
      selectedGeneralDetailFilterValues2
    );

    const handleSubmit = () => {
 
    }



  return (
    <div>

      {/* //second tab dropdowns start */}
<div className="d-flex flex-wrap justify-content-between mt-3">
            {loading.getFilters && "Loading..."}
            {levelsDropdown?.dropDownFilters
              ? Object.keys(levelsDropdown.dropDownFilters)?.map(
                  (dropdown, index) => {
                    if (index === 0) {
                      return (
                        <div key={index} className="mb-1">
                          <label htmlFor="">
                            {levelsDropdown?.levelsJSON?.[index]["name"]}
                          </label>
                          <select
                            style={{ height: "32px", width: "360px" }}
                            className="form-select form-control form-select-sm fontSize_12"
                            onChange={(e) =>
                              handleGeneralDetailFilterDropdownChange(
                                e,
                                dropdown
                              )
                            }
                            value={
                              selectedGeneralDetailFilterValues2[dropdown] || ""
                            }
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {levelsDropdown?.dropDownFilters[dropdown]?.map(
                              (option) => {
                                return (
                                  <>
                                    {console.log(
                                      "Premission",
                                      option?.Permission
                                    )}
                                    {(option?.Permission?.includes(
                                      auth?.userName
                                    ) ||
                                      option?.Permission?.includes(
                                        auth?.id?.toString()
                                      )) && (
                                      // logic
                                      <option
                                        key={option?.id}
                                        value={option?.id}
                                      >
                                        {option?.name}
                                      </option>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </select>
                        </div>
                      );
                    } else {
                      const previousDropdownSelectedValue =
                        selectedGeneralDetailFilterValues2[
                          Object.keys(levelsDropdown.dropDownFilters)[index - 1]
                        ];
                      return (
                        <div key={index} className="mb-1">
                          <label htmlFor="">
                            {levelsDropdown?.levelsJSON?.[index]["name"]}
                          </label>
                          <select
                            value={
                              selectedGeneralDetailFilterValues2[dropdown] || ""
                            }
                            style={{ height: "32px", width: "360px" }}
                            onChange={(e) =>
                              handleGeneralDetailFilterDropdownChange(
                                e,
                                dropdown,
                                levelsDropdown?.dropDownFilters[dropdown][
                                  previousDropdownSelectedValue
                                ]
                              )
                            }
                            className="form-select form-control form-select-sm fontSize_12"
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {console.log(
                              "previousDropdownSelectedValue",
                              levelsDropdown?.dropDownFilters[dropdown][
                                previousDropdownSelectedValue
                              ]
                            )}
                            {/* <option key={-1} value={-1} disabled={previousDropdownSelectedValue == -1 }>All</option> */}
                            <option key={-1} value={-1}>
                              All
                            </option>
                            {/* Render options based on the selected value of the previous dropdown */}
                            {levelsDropdown?.dropDownFilters[dropdown][
                              previousDropdownSelectedValue
                            ]?.map((option) => {
                              return (
                                <option key={option?.id} value={option?.id}>
                                  {option?.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      );
                    }
                  }
                )
              : ""}
          </div>
{/* //second tab dropdowns end */}




        <div className="d-flex flex-wrap justify-content-between align-items-center mt-3">
            <div className="mb-1">
                          <label htmlFor="">
                            Select Wave
                          </label>
                          <select
                            style={{ height: "32px", width: "360px" }}
                            className="form-select form-control form-select-sm fontSize_12"
                            onChange={(e) => handelWave(e, 0) }
                            value={selectedWave2[0]?.id || ""}
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {WavesList.map((cur, i) => (
                                 <option key={cur?.name} value={cur?.id}>
                                 {cur?.name}
                              </option>
                            ))}
                          
                          </select>
             </div>

             <div className="mb-1">
                          <label htmlFor="">
                            Select Wave
                          </label>
                          <select
                            style={{ height: "32px", width: "360px" }}
                            className="form-select form-control form-select-sm fontSize_12"
                            onChange={(e) => handelWave(e, 1) }
                            value={selectedWave2[1]?.id || ""}
                            aria-label="Default select example"
                          >
                            <option selected>select option</option>
                            {WavesList.map((cur, i) => (
                              (selectedWave2[0]?.id && cur.id > selectedWave2[0]?.id) ? (
                                 <option key={cur?.name} value={cur?.id}>
                                 {cur?.name}
                              </option>
                               ) : null
                            ))}
                          
                          </select>
             </div>

                          
             <div className="mb-1"  style={{ width: "360px" }}>
           
{console.log("selectedWave2", selectedWave2)}
             <button
            onClick={getComparisionResut} 
            type="button"
            className="btn btn-primary px-4"
            style={{marginTop: "25px"}}
            // disabled={(selectedWave2?.length !== 2 && Object?.keys(selectedGeneralDetailFilterValues2)?.length !== 3)}
            disabled={selectedWave2?.length + Object?.keys(selectedGeneralDetailFilterValues2)?.length !== 5}

         
          >
            {loading.counterMeasureReport ? ". . ." : "Submit"}

          </button>
            </div>

            </div>

            
          

              <div className="d-flex flex-wrap justify-content-between mt-4">
                <div className="m-2" style={{width: "240px"}}>
                <Card
              title="New"
              Image={Artboard71}
              text={`${counterMeasureReport?.counts ? counterMeasureReport?.counts?.new : 0}`}
              iconColor="#009d00"
            />
                </div>

                <div className="m-2" style={{width: "240px"}}>
                <Card
              title="Current"
              Image={Artboard71}
              text={`${counterMeasureReport?.counts ? counterMeasureReport?.counts?.current : 0}`}
              iconColor="#009d00"
            />
                </div>

                <div className="m-2" style={{width: "240px"}}>
                <Card
              title="Resolved"
              Image={Artboard71}
              text={`${counterMeasureReport?.counts ? counterMeasureReport?.counts?.resolved  : 0}`}
              iconColor="#009d00"
            />
                </div>

                <div className="m-2" style={{width: "240px"}}>
                <Card
              title="Previous"
              Image={Artboard71}
              text={`${counterMeasureReport?.counts ? counterMeasureReport?.counts?.previous  : 0}`}
              iconColor="#009d00"
            />
                </div>

                <div className="m-2" style={{width: "240px"}}>
                <Card
              title="Repeated"
              Image={Artboard71}
              text={`${counterMeasureReport?.counts ? counterMeasureReport?.counts?.same  : 0}`}
              iconColor="#009d00"
            />
                </div>

        
              </div>

         
            
              {loading.counterMeasureReport && (
                <div className="initail_Message">Loading...</div>
              )}
              
            {/* <div className="d-flex flex-wrap justify-content-around mt-3"> */}

            {!loading.counterMeasureReport && (

              //new code start
              <Accordion className="mt-3" defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Questionniare</Typography>
              </AccordionSummary>
              <AccordionDetails>

              <div className="d-flex align-items-baseline justify-content-end mb-3">
              {/* <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary px-4"
                disabled={
                  selectedWave3 === "" ||
                  Object?.keys(selectedGeneralDetailFilterValues3)?.length !== 3
                }
              >
                Search
              </button> */}
    <CSVLink data={csvData} filename={`${lastSelectedName} Counter Measure Report`}>
          <button
                type="button"
                className="btn btn-primary px-4"
                disabled={!counterMeasureReport?.qv}
              >
                Download CSV
              </button>
      </CSVLink>

            </div>

                <table class="table questionare_Table" >
                  <thead>
                    <tr>
                      <th className="border" scope="col">
                        Serial&nbsp;No.
                      </th>
                      <th className="border" scope="col">
                        Questions
                      </th>
                      <th className="border" scope="col">
                       Previous&nbsp;Marks
                      </th>
                      <th className="border" scope="col">
                        Active&nbsp;Marks
                      </th>
                      <th className="border" scope="col">
                      Previous&nbsp;Comments
                      </th>
                      <th className="border" scope="col">
                      Active&nbsp;Comments
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    { counterMeasureReport?.qv && Object?.entries(counterMeasureReport?.qv)?.map((cur, index) => (
                      <>
                      {console.log("cur=-=", cur, "cur[0]", cur[0])}
                        <tr
                          className="border"
                          style={{
                            backgroundColor: "#36508D",
                            color: "white",
                          }}
                        >

                          <th scope="row"></th>
                          <td>
                            <b>{cur[0]}</b>
                          </td>
                          {/* <td></td>  */}
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        {console.log("Object.keys(cur[1]).length ", Object.keys(cur[1]).length )}

                        {Object.keys(cur[1]).length === 0 &&
                        <tr>
                            {console.log("Object.keys(cur[1]).length ", Object.keys(cur[1]).length )}
                            <td className=""></td>
                            <td className="">{"No Data"}</td>
                            <td className=""></td>
                            <td className=""></td>
                            <td className=""></td>
                            <td className=""></td>
                          </tr>
                       
                        }

                        {Object.entries(cur[1]).map((sub, i) => (

                          <tr>
                            {console.log("sub=-=", sub, "sub[1].caption", sub[1] )}
                            <td className="border">{i + 1}</td>

                            <td className="border">{sub[1]?.caption}</td>
                            <td className="border text-center">
                              {sub[1]?.previousAcheivedValue}
                            </td>
                            <td className="border text-center">
                              {sub[1]?.currentAcheivedValue}
                            </td>
                            <td className="border">{sub[1]?.previousComment}</td>
                            <td className="border">{sub[1]?.currentComment}</td>

                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
              // new code ends

              // old code start
        // <Accordion className="mt-3" defaultExpanded={true}>
        //           <AccordionSummary
        //             expandIcon={<ExpandMoreIcon />}
        //             aria-controls="panel1a-content"
        //             id="panel1a-header"
        //           >
        //             <Typography>Questionniare</Typography>
        //           </AccordionSummary>
        //           <AccordionDetails>
        //             <table class="table questionare_Table" >
        //               <thead>
        //                 <tr>
        //                   <th className="border" scope="col">
        //                     Serial&nbsp;No.
        //                   </th>
        //                   <th className="border" scope="col">
        //                     Questions
        //                   </th>
        //                   <th className="border" scope="col">
        //                    Previous&nbsp;Marks
        //                   </th>
        //                   <th className="border" scope="col">
        //                     Active&nbsp;Marks
        //                   </th>
        //                   <th className="border" scope="col">
        //                   Previous&nbsp;Comments
        //                   </th>
        //                   <th className="border" scope="col">
        //                   Active&nbsp;Comments
        //                   </th>
        //                 </tr>
        //               </thead>
        //               <tbody>
        //                 { counterMeasureReport && Object.entries(counterMeasureReport)?.map((cur, index) => (
        //                   <>
        //                   {console.log("cur=-=", cur, "cur[0]", cur[0])}
        //                     <tr
        //                       className="border"
        //                       style={{
        //                         backgroundColor: "#36508D",
        //                         color: "white",
        //                       }}
        //                     >

        //                       <th scope="row"></th>
        //                       <td>
        //                         <b>{cur[0]}</b>
        //                       </td>
        //                       {/* <td></td>  */}
        //                       <td></td>
        //                       <td></td>
        //                       <td></td>
        //                       <td></td>
        //                     </tr>

        //                     {console.log("Object.keys(cur[1]).length ", Object.keys(cur[1]).length )}

        //                     {Object.keys(cur[1]).length === 0 &&
        //                     <tr>
        //                         {console.log("Object.keys(cur[1]).length ", Object.keys(cur[1]).length )}
        //                         <td className=""></td>
        //                         <td className="">{"No Data"}</td>
        //                         <td className=""></td>
        //                         <td className=""></td>
        //                         <td className=""></td>
        //                         <td className=""></td>
        //                       </tr>
                           
        //                     }

        //                     {Object.entries(cur[1]).map((sub, i) => (

        //                       <tr>
        //                         {console.log("sub=-=", sub, "sub[1].caption", sub[1] )}
        //                         <td className="border">{i + 1}</td>

        //                         <td className="border">{sub[1]?.caption}</td>
        //                         <td className="border text-center">
        //                           {sub[1]?.previousAcheivedValue}
        //                         </td>
        //                         <td className="border text-center">
        //                           {sub[1]?.currentAcheivedValue}
        //                         </td>
        //                         <td className="border">{sub[1]?.previousComment}</td>
        //                         <td className="border">{sub[1]?.currentComment}</td>

        //                       </tr>
        //                     ))}
        //                   </>
        //                 ))}
        //               </tbody>
        //             </table>
        //           </AccordionDetails>
        //         </Accordion>
         // old code ends
)}
            {/* </div> */}
    </div>
  )
}

export default QuestionMR
