import axios from "axios";
import {
  apiv11_webClient,
  apiv1_Dashboard,
  apiv1_DashboardFiltersSetting,
  apiv1_projectClassificationOneOffSettings,
  apiv1_ProjectSetting,
  apiv1_ProjectWaveSetting,
} from "../constants";
const BASE_URL = "http://54.179.225.22:6150/api/v1/webClient";
// const BASE_URL = "http://192.168.100.212:6150/api/v1/webClient";
// const BASE_URL = "http://167.71.76.48:6150/api/v1/webClient";
// const BASE_URL = "http://192.168.0.125:6150/api/v1/webClient";
// const BASE_URL = "http://192.168.100.64:6150/api/v1/webClient";


const URL = "http://54.179.225.22:6150";
// const URL = "http://192.168.100.212:6150";
// const URL = "http://167.71.76.48:6150";
// const URL = "http://192.168.0.125:6150";
// const URL = "http://192.168.100.64:6150";



export const userLogin = async (body) => {
  const res = await axios.post(`${BASE_URL}/auth`, body);
  return res;
};
export const getAllProjectsByUserId = async (id) => {
  const res = await axios.get(`${BASE_URL}/getProjectsByUserId/${id}`);
  return res;
};

export const getProjectSection = async (id) => {
  const res = await axios.get(`${BASE_URL}/getProjectSectionByProject/${id}`);
  return res;
};
// export const getProjectRegions = async ({ userId, projectId }) => {
//   const res = await axios.get(
//     `${BASE_URL}/getUserRegionProjectWise/${userId}/${projectId}`
//   );
//   return res;
// };
export const getProjectRegions = async ({ projectId }) => {
  const res = await axios.get(
    `${BASE_URL}/getProjectRegionsByProjectId/${projectId}`
  );
  return res;
};
export const SyncWebClientData = async (body) => {
  const res = await axios.post(`${BASE_URL}/SyncWebClientData`, body);
  return res;
};

export const fetchWebClientSettings = async (projectSettingId) => {
  const res = await axios.get(
    `${URL}${apiv1_ProjectSetting}/getWebClientSettingsByProjectSettingId/${projectSettingId}`
  );
  return res;
};

export const fetchWebClientSettingsByURL = async (projectURI) => {
  const res = await axios.get(
    `${BASE_URL}/getWebClientSettingsByProjectURI/${projectURI}`
  );
  return res;
};

export const gmailSignUp = async (body) => {
  const res = await axios.post(`${BASE_URL}/signupForGmail`, body);
  return res;
};

export const fetchProjectSectionByURL = async (projectURI) => {
  const res = await axios.get(
    `${BASE_URL}/getProjectSectionByProjectURI/${projectURI}`
  );
  return res;
};

export const fetchClassificationOneOffSetting = async (projectId) => {
  const res = await axios.get(
    `${URL}${apiv1_projectClassificationOneOffSettings}/${projectId}`
  );
  return res;
};

export const fetchWebClientRunByOption = async (projectId) => {
  const res = await axios.get(
    `${URL}${apiv11_webClient}/webClientRunByOption/${projectId}`
  );
  return res;
};



export const getFiltersSetting = async (projectId) => {
  return await axios.get(`${URL}${apiv1_DashboardFiltersSetting}/${projectId}`);
};

export const fetchFilteredDocumentByLevels = async (payload = {}) => {
  return await axios.post(
    `${URL}${apiv1_Dashboard}/getFilteredDocumentByLevels`,
    payload
  );
};

export const generateCounterMeasureReport = async (payload) => {
  return await axios.post(
    `${URL}${apiv1_Dashboard}/generateCounterMeasureReport`,
    payload
  );
};



export const fetchProjectWavesForDropDown = async (projectId) => {
  return await axios.get(`${URL}${apiv1_ProjectWaveSetting}/getProjectWaveForDDByProjectId/${projectId}`);
};


export const getQvForWave = async (payload) => {
  return await axios.post(
    `${URL}${apiv1_Dashboard}/getQvForWave`,
    payload
  );
};

export const clientComments = async (payload) => {
  return await axios.put(
    `${URL}${apiv1_Dashboard}/clientComments`,
    payload
  );
};




// export const fetchProjectWavesForDropDown = async (projectId) => {
//   return new Promise((resolve, reject) => {
//     axios
//       .get(
//         `${URL}${apiv1_ProjectWaveSetting}/getProjectWaveForDDByProjectId/${projectId}`
//       )
//       .then((res) => {
//         resolve(res.data);
//       })
//       .catch((err) => {
//         reject(err.response);
//       });
//   });
// };


